import Autocomplete from '@mui/material/Autocomplete';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';

export const AutocompleteInput = ({
  name,
  options,
  noMargin,
  defaultValue,
  onChange,
  ...rest
}: AutocompleteInputProps) => {
  const { setValue, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ''}
      render={({ field, fieldState }) => (
        <FieldWrapper noMargin={noMargin}>
          {/* <pre>{JSON.stringify({ field, fieldState }, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify({ defaultValue }, null, 2)}</pre> */}
          <Autocomplete
            value={defaultValue || ''}
            disablePortal
            freeSolo // Set freeSolo to true so the textbox can contain any arbitrary value.
            onInputChange={(event, newInputValue) => {
              setValue(name, newInputValue);
              if (onChange) {
                onChange(newInputValue);
              }
            }}
            options={options}
            renderInput={(params) => (
              <TextFieldExtended
                {...params}
                {...field}
                fullWidth
                {...rest}
                helperText={fieldState.error ? fieldState.error.message : ''}
                color={fieldState.error ? 'error' : 'primary'}
              />
            )}
            slotProps={{
              popper: {
                sx: {
                  zIndex: 2,
                },
              },
            }}
          />
        </FieldWrapper>
      )}
    />
  );
};

type AutocompleteInputProps = {
  options: string[];
  name: string;
  noMargin?: boolean;
  onChange?: (value: string) => void;
} & TextFieldProps;

const TextFieldExtended = styled(TextField)`
  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;
