import React from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from '../../atoms/Icons';
import { IconButton } from '../../atoms/Icons/Icon';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const VerticalNav = ({
  children,
  isOpen,
  setIsOpen,
}: React.PropsWithChildren<{ isOpen: boolean; setIsOpen?: (isOpen: boolean) => {} }>) => (
  <Wrapper $isOpen={isOpen}>
    {Boolean(setIsOpen) && (
      <SwitchContainer>
        <IconButton onClick={() => setIsOpen && setIsOpen(!isOpen)}>
          {isOpen ? (
            <ChevronLeftIcon size={2} color={Colors.Contrast} />
          ) : (
            <ChevronRightIcon size={2} color={Colors.Contrast} />
          )}
        </IconButton>
      </SwitchContainer>
    )}

    <ol>{children}</ol>
  </Wrapper>
);

const Wrapper = styled.nav<{ $isOpen: boolean }>`
  > ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const SwitchContainer = styled.div`
  display: none;
  ${media.from_tablet`
    display: block;
  `}
`;
