import { NavigateNext } from '@styled-icons/material';
import { Icon, IconProps } from './Icon';

export function NavigateNextIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <NavigateNext />
    </Icon>
  );
}
