import React from 'react';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const VerticalNavStatus = ({ active, enabled, icon, ...props }: VerticalNavStatusProps) => {
  return (
    <Wrapper $active={active} $enabled={enabled} {...props}>
      {icon}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ $active?: boolean; $enabled?: boolean }>`
  opacity: ${({ $active, $enabled }) => (!$active && !$enabled ? '0.5' : '1')};
  div {
    width: 1em;
    ${media.from_tablet`
      width: 1.5em;
    `}
  }
  svg {
    color: ${({ $active, $enabled }) => {
      if ($active) {
        return Colors.White;
      } else if ($enabled) {
        return Colors.Primary;
      } else {
        return Colors.Contrast;
      }
    }};
  }
`;

interface VerticalNavStatusProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  enabled?: boolean;
  icon?: any;
}
