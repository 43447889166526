import React from 'react';
import styled, { css } from 'styled-components';

import markerRight from './marker-right.svg';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const VerticalNavItem = ({
  children,
  active,
  enabled,
  onClick,
  hasErrors,
  isSmall = false,
  icon,
  isOpen = true,
}: React.PropsWithChildren<VerticalNavItemProps>) => {
  return (
    <Item icon={icon} $isSmall={isSmall} $enabled={enabled} $active={active} $hasErrors={hasErrors} onClick={onClick}>
      <Marker $active={active} />
      <Children $enabled={enabled}>
        {icon && <Icon>{icon}</Icon>}
        {isOpen && <Label>{children}</Label>}
      </Children>
    </Item>
  );
};

const Item = styled.li<{ $isSmall?: boolean; $active?: boolean; $enabled?: boolean; $hasErrors?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${Colors.Black};
  transition: all 0.3s linear;
  cursor: pointer;

  ${({ $isSmall }) =>
    $isSmall
      ? css`
          height: 48px;
          ${media.from_laptop`
            height: 62px;
            font-size: 1.2rem;
            max-width: 300px;
          `}
        `
      : css`
          height: 72px;
          ${media.from_laptop`
            height: 96px;
            font-size: 1.3rem;
            max-width: 400px;
          `}
        `}

  ${({ $enabled: enabled }) =>
    enabled &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${Colors.PrimaryLightest};
      }
    `}

  ${({ $active: active }) => active && `color: ${Colors.White};`}
    ${({ $hasErrors: hasErrors, $active: active }) =>
    hasErrors &&
    !active &&
    `
      color: ${Colors.Contrast};
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        height: 100%;
        background-color: ${Colors.Contrast};
      }

      svg {
        color: ${Colors.Contrast}!important;
      }
    `}
`;

const Marker = styled.div<{ $active?: boolean }>`
  ${media.from_tablet`
    &:after {
      content: '';
    }
  `}

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.PrimaryDarkest};
  transition: all 0.25s ease-out;
  transform: translateX(-100%);
  margin-left: -14px;
  &:after {
    width: 14px;
    height: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    background-image: url(${markerRight});
    background-size: 100% 100%;
  }

  ${({ $active }) => $active && `transform: translateX(0); margin-left: 0`}
`;

const Children = styled.div<{ $enabled?: boolean }>`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 16px;
  width: 100%;

  ${({ $enabled = false }) =>
    !$enabled &&
    `
    font-weight: 100;
    color: ${Colors.GreyLightest};
    svg {
      color: ${Colors.GreyLightest}!important;
    }
  `}
`;

const Label = styled.span`
  margin: auto auto auto 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 64px;
  padding: 0 16px 0 0;
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px !important;
  height: 64px;
  flex: 0 0 64px;
`;

interface VerticalNavItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
  isOpen?: boolean;
  icon?: React.ReactNode;
  active?: boolean;
  enabled?: boolean;
  hasErrors?: boolean;
  isSmall?: boolean;
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}
